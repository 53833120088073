import type { AsVuexStore } from '@zento-lib/components/vuex';

export const KEY = 'wishlist';

export type IVSFWishlist = AsVuexStore<
{
  loaded: boolean;
  items: any[];
},
'isWishlistLoaded',
'clear' | 'load' | 'addItem' | 'removeItem',
'wishlist/ADD' | 'wishlist/DEL' | 'wishlist/LOAD' | 'wishlist/SET_WISHLIST_LOADED'
>;
